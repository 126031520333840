import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteSharp from "@mui/icons-material/DeleteSharp";

export default function AlertDialog(props) {
  const { itemTitle, onConfirm } = props;

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRemove = () => {
    onConfirm();
    setOpen(false);
  };

  return (
    <div className="dropchance-item__icon-container dropchance-item__icon-container--trash">
      <div className="dropchance-item__icon--trash" onClick={handleClickOpen}>
        <DeleteSharp />
      </div>
      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth={true}>
        <DialogTitle id="alert-dialog-title">Remove item</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Confirm to remove{" "}
            {itemTitle ? '"' + itemTitle + '"' : " empty item"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className="button button--gray" onClick={handleClose}>
            Cancel
          </div>
          <div className="button button--red" onClick={handleRemove}>
            Remove
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
